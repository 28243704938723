
import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button, Input, Table } from 'reactstrap';
import { Icon, TextField } from '@material-ui/core';
import ApiEndpoints from "../../../constants/api_endpoints";
import "../../../styles/button.css";
import { uploadFile} from "../../../actions";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import SelectList from "../../global/form/select";
import InputField from "../../global/form/input";
import ConfirmAction from "../../global/confirm-action";

class TaskLeadAdd extends Component {
    constructor(props) {
      super(props);
      this.state = {
            formInputs: [],
            pmwId: null,
            inputValues : {},
            isOpenConfirm : false,
            confirmMessage: null
        }
    }

    async componentDidMount(){
        let { jobTask } = this.props;
        let formInputs = [];
        if(jobTask.formData && jobTask.formData.input)
            jobTask.formData.input.sections.map(section => 
                section.input_groups.map(inputGroup => 
                    formInputs.push(...inputGroup.inputs)
                )
            )
        this.setState({ formInputs })
    }

    onChangeInput = (input, value) => {
        let { inputValues } = this.state;

        if(input.input_type === "CHECKBOX"){
            inputValues[input.input_id] = inputValues[input.input_id] || [];
            const index = inputValues[input.input_id].indexOf(value);
            if (index > -1) inputValues[input.input_id].splice(index, 1);
            else inputValues[input.input_id].push(value);
        } 
        else inputValues[input.input_id] = value;

        this.setState({inputValues})
    }


    addNewLead = async () => {
        let { pmwId, formInputs, inputValues } = this.state;
        let { jobTask } = this.props;
        store.dispatch(setLoader(true));
        try{
            var userSearchBody = { limit: 1, search : pmwId };
            let userSearchResp = await ApiEndpoints.User.fetchUsersByFilter(userSearchBody);
            let userSearchData = userSearchResp.data.data.rows[0];
            if(!userSearchData) throw new Error("Not Found Partner");

            for(let i = 0; i < formInputs.length; i++){
                let inputType = formInputs[i].input_type;
                if(["IMAGE", "VIDEO", "FILE"].includes(inputType) && inputValues[formInputs[i].input_id] ){
                    let fileResp = await uploadFile(inputValues[formInputs[i].input_id], "FORM_RESPONSE");
                    inputValues[formInputs[i].input_id] = fileResp.file_id;
                }
                else if(inputType === "CHECKBOX" && inputValues[formInputs[i].input_id]) inputValues[formInputs[i].input_id] = inputValues[formInputs[i].input_id].join(";;"); 
            }        


            var body = {
                user_id: userSearchData.user_id,
                form_id: jobTask.form_id,
                input: Object.keys(inputValues).map(inputId => ({ input_id: inputId, value: inputValues[inputId] }))
            }
            let resp = await ApiEndpoints.JobTask.addResponse(body);
            this.props.onClose && this.props.onClose();
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));
       
    }



    onClose = () => {
        this.props.onClose && this.props.onClose();
    }

    render() {
        let { formInputs, pmwId, inputValues } = this.state;
        let { jobTask, jobData } = this.props;
        var userRole = store.getState().user.UserRole;
       
        return(
            <>
            <div className="">
                <Row>
                    <Col xs={12}>
                        <span>Partner PMW ID</span>
                        <InputField placeholder="Enter PMW ID with PMW prefix"  value={pmwId || ""} onChange={(e) => this.setState({ pmwId: e.target.value }) } />
                    </Col>
                    {formInputs.filter(input => input.input_by !== "SYSTEM").map((input, i) => 
                        <Col xs={12}>
                            <span className="small mt-3 d-block">{input.label} {!!input.validation && !!input.validation.required && <span className="text-danger">*</span>}</span>
                            {renderInput(input, inputValues, this.onChangeInput)}
                        </Col>
                    )}
                </Row>
                <Row className="justify-content-end">
                    <Col xs="auto" className="my-2">
                        <Button size="sm" type="button" color="secondary" onClick={() => this.onClose()}>Cancel</Button>
                        <Button size="sm" type="button" color="dark" className="ml-3" onClick={() => this.addNewLead()}>Add Lead</Button>
                    </Col>
                </Row>
            </div>


            <ConfirmAction message={this.state.isConfirmMessage} isOpen={!!this.state.isConfirm} onClose={() => this.setState({isConfirm : false})} callback={this.state.isConfirm} />
        </>
        )
    }

}
export default TaskLeadAdd;

const renderInput = (input, inputValues, onChangeInput ) => {
    switch(input.input_type) {
        case "FILE": case "IMAGE": case "VIDEO":
            return <input type="file" onChange={(e) => onChangeInput(input, e.target.files[0]) } />
            break;
        case "DROPDOWN":
            return <SelectList value={inputValues[input.input_id] || ""} onChange={(e) => onChangeInput(input, e.target.value) } placeholder={input.placeholder || "Select"} >
                {input.input_enum.map(v => <option value={v}>{v}</option>)}
            </SelectList>
            break; 
        case "CHECKBOX":
            return <>
                {input.input_enum.map(v => <label className="small d-block"><input className="align-middle mr-2" type="checkbox" checked={(inputValues[input.input_id] || []).includes(v)} onChange={(e) => onChangeInput(input, v)} />{v}</label>)}
            </>
            break;
        case "TEXTAREA":
           return <TextField className="w-100" multiline defaultValue="Enter here" minRows={3}  value={inputValues[input.input_id] || ""} onChange={(e) => onChangeInput(input, e.target.value) }  placeholder={input.placeholder || "Enter"} />
            break;
        default:
            return <InputField value={inputValues[input.input_id] || ""} onChange={(e) => onChangeInput(input, e.target.value) }  placeholder={input.placeholder || "Enter"} />
            break;
    }
}
